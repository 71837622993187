<template>
  <div class="admin-tool-utm">
    <div class="alert alert-secondary font-sm">
      <div class="mt-1">
        <span class="badge badge-primary">예시</span>
        <span class="ml-2">https://www.ohmycompany.com/reward/1234567?utm=eyJzb3VyY2UiOiJ0ZXN0IiwibWVkaXVtIjoidGVz</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 left">
        <div class="form-group">
          <label :for="`${component.name}Source`">
            <span class="color-purple">*</span>
            <span> 유입 경로</span>
            <span>(Source)</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Source`" placeholder="ex) google, facebook, naver" v-model="state.form.source" @keyup.enter="encode()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}Medium`">
            <span class="color-purple">*</span>
            <span> 매체 방식</span>
            <span>(Medium)</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Medium`" placeholder="ex) video, display, keyword" v-model="state.form.medium" ref="mediumRef" @keyup.enter="encode()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}Campaign`">
            <span class="color-purple">*</span>
            <span> 캠페인</span>
            <span>(Campaign)</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Campaign`" placeholder="ex) 오마이컴퍼니와 함께하는 마케팅 입문 강의" v-model="state.form.campaign" @keyup.enter="encode()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}Content`">
            <span>소재</span>
            <span>(Content)</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Content`" placeholder="ex) 동영상1, 동영상2, 디스플레이1, 디스플레이2" v-model="state.form.content" @keyup.enter="encode()">
        </div>
        <div class="form-group">
          <label :for="`${component.name}Term`">
            <span>키워드</span>
            <span>(Term)</span>
          </label>
          <input type="text" class="form-control" :id="`${component.name}Term`" placeholder="ex) 오마이컴퍼니, 마케팅, 광고, 홍보" v-model="state.form.term" @keyup.enter="encode()">
        </div>
        <div class="act">
          <button type="button" class="btn btn-primary btn-lg btn-block" @click="encode()">인코딩</button>
        </div>
      </div>
      <div class="col-12 col-lg-6 right">
        <div class="form-group">
          <label :for="`${component.name}EncodedResult`">
            <span class="color-purple">*</span>
            <span> UTM 태그</span>
          </label>
          <textarea :id="`${component.name}EncodedResult`" class="form-control" v-model="state.encodedResult" @keyup.ctrl.enter="decode()"></textarea>
        </div>
        <div class="acts">
          <button type="button" class="decode btn btn-default btn-lg btn-block" @click="decode()">디코딩</button>
          <button :id="`${component.name}encodeCopy`" title="인코딩 값 복사하기" type="button" class="copy btn btn-default btn-lg btn-block" @click="copy()">
            <i class="ni ni-single-copy-04"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageAdminUtmGenerator";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      loaded: false,
      form: {
        source: "",
        medium: "",
        campaign: "",
        content: "",
        term: "",
      },
      encodedResult: "",
    });

    const encode = () => {
      if (!state.form.source?.trim()) {
        document.getElementById(`${component.name}Source`)?.focus();
        return store.commit("setSwingMessage", "유입 경로를 입력해주세요.");
      } else if (!state.form.medium?.trim()) {
        document.getElementById(`${component.name}Medium`)?.focus();
        return store.commit("setSwingMessage", "매체 방식을 입력해주세요.");
      } else if (!state.form.campaign?.trim()) {
        document.getElementById(`${component.name}Campaign`)?.focus();
        return store.commit("setSwingMessage", "캠페인을 입력해주세요.");
      }

      state.encodedResult = lib.getEncodedString(state.form);
      store.commit("setSwingMessage", "인코딩 작업을 완료하였습니다.");
    };

    const decode = () => {
      if (!state.encodedResult) {
        document.getElementById(`${component.name}EncodedResult`)?.focus();
        return store.commit("setSwingMessage", "UTM 값을 입력해주세요.");
      }

      state.encodedResult = state.encodedResult.trim();
      const result = lib.getDecodedObject(state.encodedResult);

      if (!result) {
        document.getElementById(`${component.name}EncodedResult`)?.focus();
        return store.commit("setSwingMessage", "입력하신 UTM 값을 확인해주세요.");
      }

      state.form = {
        source: result.source,
        medium: result.medium,
        campaign: result.campaign,
        content: result.content,
        term: result.term,
      };

      store.commit("setSwingMessage", "디코딩 작업을 완료하였습니다.");
    };

    const copy = () => {
      const $textarea = document.getElementById(`${component.name}EncodedResult`);
      $textarea.select();
      document.execCommand("copy");
      store.commit("setSwingMessage", "UTM 값을 복사하였습니다.");
    };

    return {component, state, encode, decode, copy};
  }
});
</script>

<style lang="scss" scoped>
.admin-tool-utm {
  > .alert {
    background: $colorBackground;
    border: $px1 solid #eee;
    margin-bottom: $px25;
    padding-left: $px20;
    padding-right: $px20;
  }

  > .row > div {
    margin: 0 0 $px50 0;

    .form-group {
      > label {
        font-size: $px14;
      }
    }

    &.left .act {
      padding-top: $px5;
    }

    &.right {
      position: relative;

      textarea {
        height: calc(100% - 111px);
        position: absolute;
        width: calc(100% - 30px);
        left: $px15;
        top: $px31;
        resize: none;
      }

      .acts {
        position: absolute;
        left: $px15;
        bottom: 0;
        width: calc(100% - 30px);
        padding-right: $px60;

        .btn {
          background: $colorBackground;
          color: #3c3c3c;

          &.copy {
            position: absolute;
            top: 0;
            right: 0;
            width: $px51;
            height: 100%;
            margin: 0;
          }

          &:hover {
            background: #eee;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .row > div.right {
      .acts {
        position: static;
        padding-right: 0;
        width: 100%;

        .btn {
          position: static;
          width: calc(100% - 60px);
          display: inline-block;

          &.copy {
            position: static;
          }
        }
      }

      textarea {
        position: static;
        width: 100%;
        min-height: $px200;
      }
    }
  }
}
</style>